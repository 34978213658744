import {API} from "../api/api";
import {getActualToken, getProfileInfo, setInfoUsers, setUsers} from "./users-reducer";

const initState = {
    complainersList: [],
    complainersUsers: [],
    complainersToUsers: [],
}

export const complainersReducer = (state = initState, action)=> {
    switch (action.type) {
        case 'GET_COMPLAINS_LIST': {
            return {...state, complainersList: action.value}
        }
        case 'GET_COMPLAINS_USERS': {
            return {...state, complainersUsers: [...state.complainersUsers.concat(action.value)]}
            // debugger
            // return {...state, complainersUsers: [action.value]}
        }
        case 'GET_COMPLAINS_TO_USERS': {
            return {...state, complainersToUsers: [...state.complainersToUsers.concat(action.value)]}
        }
        case 'GET_PROFILE_INFO': {
            return {...state, complainersUsers: action.user}
        }
        case 'CLEAR_US_INFO': {
            return {...state, complainersUsers: [], complainersToUsers: []}
        }
        default:
            return state
    }
}

export const getComplainersListAC = (value) => {
    return {type: 'GET_COMPLAINS_LIST', value}
}
export const getComplainersUsersAC = (value) => {
    return {type: 'GET_COMPLAINS_USERS', value}
}
export const getComplainersToUsersAC = (value) => {
    return {type: 'GET_COMPLAINS_TO_USERS', value}
}
export const clearUsersInfoComplainersAC = () => {
    return {type: 'CLEAR_US_INFO'}
}

// export const getComplaintsListTC = (key, status, dateFrom, dateTo ) => (dispatch) => {
//     let complains = []
//     API.getComplaintsList(key, status, dateFrom, dateTo)
//         .then((res) => {
//             // debugger
//             complains = res.data.result
//             dispatch(getComplainersListAC(res.data.result))
//         })
//         .then(() => {
//             console.log(complains)
//             complains.map((complain) => {
//                 dispatch(getComplainersUsersTC(key, complain.ownerPost))
//
//             })
//         })
//         .then(() => {
//             complains.map((complain) => {
//                 dispatch(getComplainersToUsersTC(key, complain.userId))
//             })
//         })
// }

export const getComplaintsListTC = (key, status, dateFrom, dateTo ) => async (dispatch) => {
    let complains = []
    const res = await API.getComplaintsList(key, status, dateFrom, dateTo)
        if (res.data.result) {
            complains = res.data.result
            await dispatch(getComplainersListAC(res.data.result))
            // debugger
            // console.log(complains.every(elem => elem.ownerPost))
            // let arra = complains.every(elem => dispatch(getComplainersUsersTC(key, elem.ownerPost)))
            // console.log(arra)
            complains.map((complain) => {
                // debugger
                dispatch(getComplainersUsersTC(key, complain.ownerPost))
                dispatch(getComplainersToUsersTC(key, complain.userId))
            })
        }
}

// export const refreshTokens = async (tokenKey) => {
//     refreshProxy.isRefreshing = true
//     const res = await API.refreshTokens(tokenKey)
//     if (res.data.error) {
//         window.location.href = '/SignIn';
//         document.location.reload()
//     }
//     let dateNow = Date.now() / 1000;
//     localStorage.setItem("tokenKey", res.data.result.access_token);
//     localStorage.setItem("tokenKeyRefresh", res.data.result.refresh_token);
//     localStorage.setItem("tokenKeyExpire", dateNow + res.data.result.durationAccess);
//     localStorage.setItem("tokenKeyRefreshExpire", dateNow + res.data.result.durationRefresh);
//     refreshProxy.setValue(res.data.result)
//     return res.data.result
// }

// export const getUsersTC = (filter, offset) => async (dispatch, getState) => {
//     const tokenKey = await getActualToken()
//     API.getUsers(tokenKey, filter, offset)
//         .then((res) => {
//             // debugger
//             // if (res) {
//             let state = getState()
//             if (res?.data?.result?.chats[0]?.lastMessageText === state?.usersPage?.users[0]?.lastMessageText) {
//                 return
//             }
//             dispatch(setUsers(res.data.result.chats))
//             dispatch(setInfoUsers(res.data.result.users))
//             // }
//         })
// }


export const getComplainersUsersTC = (key, hashUser) => (dispatch) => {
    API.getProfileForAdmin(key, hashUser)
        .then((res) => {
            dispatch(getComplainersUsersAC(res.data.result))
        })
}
export const getComplainersToUsersTC = (key, hashUser) => (dispatch) => {
    API.getProfileForAdmin(key, hashUser)
        .then((res) => {
            dispatch(getComplainersToUsersAC(res.data.result))
        })
}








// curl -H 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJhZG1pbi1hcGkuZmFudGVyLmNvbSIsInN1YiI6ImFkbWluQXBwIiwiZXhwIjoxNjQ3NTE0NDc4LCJpYXQiOjE2NDc1MTA4NzgsIm5iZiI6MTY0NzUxMDg3OCwiaFVzZXIiOiJhZG1fa2V5X3h0b2tBZG1pbkNoYXQiLCJyb2xlcyI6ImNoYXRBZG1pbiIsInRwIjoiQVQifQ.Qw-Zz4EJoUml_0uL5iFmsz_LXrOCKrj3tEz6-Nc_1udrdGzksup3eUZpnDxg_GRseBqxAY5_v-Ina8mrxrXN9opfl2XoAUVlGDO75b-O4oCf6ddMjLkc-uVjw9nEgrl6A3cfJDmWC5Zy2HBrRw_Bt1zugkhontnwCs1rr1w5Yxo' -X POST -d '{
// "id":"1",
//     "method":"chat.getChatList",
//     "params":{
// },
// "jsonrpc":"2.0"
// }' https://a.fanter.com/api/?chat.getChatList








