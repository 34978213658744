import React, {useEffect} from "react";
import {ThemeColor} from "../../Config/Config";
import NavBar from "../NavBar/NavBar";
import {Dropdown, DropdownButton} from "react-bootstrap";
import uuid from "react-uuid";
import Avatar from "react-avatar";
import {ReactComponent as DeleteSvg} from "../../assets/media/icons/block.svg";
import {ReactComponent as ChatPlusSvg} from "../../assets/media/icons/chatplus.svg";
import {useWindowWidth} from "@react-hook/window-size";

export const Transactions = () => {
    const state = {
        themeColor: Boolean(localStorage.getItem("theme"))
            ? parseInt(localStorage.getItem("theme"))
            : ThemeColor.Light,
        isRemember: false,
        isRTL: Boolean(localStorage.getItem("rtl")),
    };
    useEffect(() => {
        let classList = document.body.classList;
        if (classList.length === 0 && state.themeColor === ThemeColor.Dark) {
            document.body.classList.add("dark-skin");
        }
    }, [state.themeColor])
    const width = useWindowWidth()
    return (
        <div className="chats-tab-open h-100">
            <div className={"main-layout h-100"}>
                <NavBar activeMenu="transactions"/>
                <main className="main">
                    <div className="chats">
                        <div className="d-flex flex-column h-100 w-100">
                            <div className={"main-layout"}>
                                <aside style={{flex: 11}}>
                                    <div className="calls px-0" style={{flexDirection: 'column', paddingBottom: 20}}>
                                        <div className="page-main-heading sticky-top py-2 px-3">
                                            <div className="pl-2 pl-xl-0" style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                width: '100%'
                                            }}>
                                                <h5 className="font-weight-semibold"
                                                    style={{marginTop: 10, marginBottom: 10}}>Applications for
                                                    payment</h5>
                                                <DropdownButton
                                                    className="border-0 f-16 p-0 min-w-150 cut-texts"
                                                    variant="outline-default"
                                                    title={'Не обработанные'}
                                                >
                                                    <Dropdown.Item>Не обработанные</Dropdown.Item>
                                                    <Dropdown.Item>Исполненные</Dropdown.Item>
                                                    <Dropdown.Item>Отмененные</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </div>
                                        <div className="" style={{position: 'fixed', width: 'calc(100% - 70px)', zIndex: 1}}>
                                            <div style={{display: 'flex', width: 100 + '%'}}>
                                                <div className="container-xl">
                                                    <div className="row calls-log">
                                                        <div className="col" style={{display: 'flex', marginTop: 65, color: 'white', fontWeight: 'bold', paddingTop: 20, paddingBottom: 20, background: '#323333'}}>
                                                            <div className="col-3">User</div>
                                                            <div className="col-3">Sum</div>
                                                            <div className="col-2">Status</div>
                                                            <div className="col-4" style={{textAlign: 'right', paddingRight: 45}}>What to do</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-xl" style={{marginTop: 60}}>
                                            <div className="row calls-log">
                                                <div className="col">

                                                    <div className="card businessCard" key={uuid()}>
                                                        <div className="card-body">
                                                            <div className="media" style={{
                                                                alignItems: 'center',
                                                                justefyContent: 'space-between'
                                                            }}>
                                                                <div className="col-3">
                                                                    <div style={{display: 'flex'}}>
                                                                        <div className="avatar-primary mr-2">
                                                                                <span>
                                                                                    <Avatar
                                                                                        name={'xx'}
                                                                                        size={48}
                                                                                        round
                                                                                        src={'xx'}
                                                                                    />
                                                                                </span>
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <h6 style={{marginBottom: 0}}>
                                                                                Mister X
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div style={{display: 'flex'}}>
                                                                        <div className="media-body">
                                                                            <h6 style={{marginBottom: 0}}>
                                                                                19$
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-2">
                                                                    <div style={{display: 'flex'}}>
                                                                        <div className="media-body">
                                                                            <h6 style={{marginBottom: 0}}>
                                                                                не обработана
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-4">
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-end'
                                                                    }}>
                                                                        {
                                                                            width > 1000 ?
                                                                                <div className="media-options">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-outline-danger mx-1">
                                                                                        Reject
                                                                                    </button>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-primary mx-1">
                                                                                        Confirm
                                                                                    </button>
                                                                                </div> :
                                                                                <div>
                                                                                    <button
                                                                                        className="btn btn-link text-muted"
                                                                                        style={{padding: 2, margin: 2}}>
                                                                                        <DeleteSvg className="hw-24"
                                                                                                   style={{color: '#ff337c'}}/>
                                                                                    </button>
                                                                                    <button
                                                                                        className="btn btn-link text-muted"
                                                                                        style={{padding: 2, margin: 2}}>
                                                                                        <ChatPlusSvg className="hw-24"
                                                                                                     style={{color: '#44a675'}}/>
                                                                                    </button>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}
