import * as axios from "axios";

export const API = {
    authLogin(login, password) {
        const params = {
            "id": "1",
            "method": "auth.createAdminKey",
            "params": {
                "email": login,
                "password": password
            },
            "jsonrpc": "2.0"
        }
        return axios.post('https://a.fanter.com/api/?auth.createAdminKey', params)
    },
    refreshTokens(tokenKey) {
        const params = {
            "id": "1",
            "method": "auth.refreshAdminKey",
            "params": {
                "refresh_token": tokenKey,
            },
            "jsonrpc": "2.0"
        }
        return axios.post('https://a.fanter.com/api/?auth.refreshAdminKey', params)
    },
    getUsers(tokenKey, chatType, offset) {
        const body = {
            "id": "1",
            "method": "chat.getChatList",
            "params": {
                chatType,
                "offset": offset
            },
            "jsonrpc": "2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?chat.getChatList', body, {headers})
    },
    getMessages(chatID, tokenKey, limit, offset) {
        const body = {
            "id": "1",
            "method": "chat.getChatMessagesList",
            "params": {
                "id": chatID,
                limit,
                offset
            },
            "jsonrpc": "2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?chat.getChatMessagesList', body, {headers})
    },
    sendMessage(chatId, tokenKey, message, nickname, chatType) {
        const body = {
            "id": "1",
            "method":"chat.sendMessageFromSupport",
            "params":{
                "message": message,
                "accFrom": "01e7a7682d8a06e748814b9f636d697f81bc3057",
                "chatId": chatId,
                "nickname": nickname,
                "chatType": chatType
            },
            "jsonrpc":"2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?chat.sendMessageFromSupport', body, {headers})
    },
    editMessage(tokenKey, messageId, message) {
        const body = {
            "id": "1",
            "method":"chat.editMessage",
            "params":{
                "messageId": messageId,
                "message": message,
            },
            "jsonrpc":"2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?chat.editMessage', body, {headers})
    },
    createChat(tokenKey, hashUser, chatType) {
        const body = {
            "id": "1",
            "method":"chat.createChat",
            "params":{
                "hashUser": hashUser,
                "chatType": chatType
            },
            "jsonrpc":"2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?chat.createChat', body, {headers})
    },
    findUsersForChats(tokenKey, str, offset) {
        const body = {
            "id": "1",
            "method":"user.findUserForChats",
            "params":{
                "searchStr" : str,
                offset
            },
            "jsonrpc":"2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?user.findUserForChats', body, {headers})
    },
    getIsBusinessRequests(tokenKey) {
        const body = {
            "id": "1",
            "method":"user.getIsBusinessRequests",
            "params":{
            },
            "jsonrpc":"2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?user.getIsBusinessRequests', body, {headers})
    },
    commitIsBusiness(tokenKey, hashUser) {
        const body = {
            "id": "1",
            "method":"user.commitIsBusiness",
            "params":{
                "hashUser": hashUser
            },
            "jsonrpc":"2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?user.commitIsBusiness', body, {headers})
    },
    declineIsBusiness(tokenKey, hashUser) {
        const body = {
            "id": "1",
            "method":"user.declineIsBusiness",
            "params":{
                "hashUser": hashUser
            },
            "jsonrpc":"2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?user.declineIsBusiness', body, {headers})
    },
    getUploadedDocs(tokenKey, hashUser) {
        const body = {
            "id": "1",
            "method":"user.getUploadedDocs",
            "params":{
                "hashUser": hashUser
            },
            "jsonrpc":"2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?user.getUploadedDocs', body, {headers})
    },
    getProfileForAdmin(tokenKey, hashUser) {
        const body = {
            "id": "1",
            "method":"user.getProfileForAdmin",
            "params":{
                "hashUser": hashUser,
                // "offset": 0,
            },
            "jsonrpc":"2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?user.getProfileForAdmin', body, {headers})
    },
    changeUploadedDocsState(tokenKey, hashUser, status) {
        const body = {
            "id": "1",
            "method":"user.changeUploadedDocsState",
            "params":{
                "hashUser": hashUser,
                "stateBankDoc": status,
            },
            "jsonrpc":"2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?user.changeUploadedDocsState', body, {headers})
    },
    isOnline(hashUser) {
        const body = {
            "method":"profile.isOnline",
            "params":{
                "hashUser": hashUser,
            },
            "id":1,
            "jsonrpc":"2.0"
        }
        return axios.post('https://api.fanter.com/?profile.isOnline', body)
    },
    getComplaintsList(tokenKey, status, dateFrom, dateTo) {
        const body = {
            "method":"content.getComplaintsList",
            "params":{
                "status": status,
                "dateFrom": dateFrom,
                "dateTo": dateTo,
            },
            "id":"1",
            "jsonrpc":"2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?content.getComplaintsList', body, {headers})
    },
    setFraud(tokenKey, hashUser, fraudScore) {
        const body = {
            "method":"user.setFraud",
            "params":{
                "hashUser": hashUser,
                fraudScore
            },
            "id":1,
            "jsonrpc":"2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?user.setFraud', body, {headers})
    },
}


// curl -H 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJhZG1pbi1hcGkuZmFudGVyLmNvbSIsInN1YiI6ImFkbWluQXBwIiwiZXhwIjoxNjQ2Mjk5MDcxLCJpYXQiOjE2NDYyOTU0NzEsIm5iZiI6MTY0NjI5NTQ3MSwiaFVzZXIiOiJhZG1fa2V5X3h0b2tBZG1pbkNoYXQiLCJyb2xlcyI6ImNoYXRBZG1pbiIsInRwIjoiQVQifQ.ijvvqO-KSipxqOUc6jjqv0SqgwUuo9S8sWxvtEiueg57IKK6f9Z-EwxHZtX7bUs-3TWIAwUDR2jDXXzLsYahmHTB8QcyWgG6Xq5IKvo6D-xNdjUxGaSxhw8Z-PjvAfwq3aaJ_14L33o8oJNoiCeXjoJ8gMYmXKUMpMBzySmu8mg' -X POST -d '{
//     "id":"1",
//        "method":"content.getComplaintsList",
//         "params":{
//             "status":0,
//                 "dateFrom" : 1145799999,
//                 "dateTo" : 1646299070
//         },
//     "jsonrpc":"2.0"
// }' https://a.fanter.com/api/?content.getComplaintsList


// curl -H 'Authorization: Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJhZG1pbi1hcGkuZmFudGVyLmNvbSIsInN1YiI6ImFkbWluQXBwIiwiZXhwIjoxNjQ4NDc2MzA1LCJpYXQiOjE2NDg0NzI3MDUsIm5iZiI6MTY0ODQ3MjcwNSwiaFVzZXIiOiJhZG1fa2V5X3h0b2tBZG1pbkNoYXQiLCJyb2xlcyI6ImNoYXRBZG1pbiIsInRwIjoiQVQifQ.BRu8uLaKuOC1pJxmblJWc1ApYutEh8nNtvKnB-qSXDV7AL4oAngcLqkUn2w0oNPv1-gvPNjJLbd4iSKEmygodip941TKYY5Ma5wGKMTURk5miPgagvZkRSp8-wTKys--i5IjMV-Xi32PBpa1-6xNGhg0YbrAptYE-6vvpckFSlw' -X POST -d '{"id":"1", "method":"chat.editMessage", "params":{"messageId":"32815", "message" : "изменененное сообщение"}, "jsonrpc":"2.0"}' https://a.fanter.com/api/?chat.editMessage
