import React, {useEffect} from "react";
import {ThemeColor} from "../../Config/Config";
import NavBar from "../NavBar/NavBar";
import ComplaintsList from "./ComplaintsList";

export const Complaints = () => {
    const state = {
        themeColor: Boolean(localStorage.getItem("theme"))
            ? parseInt(localStorage.getItem("theme"))
            : ThemeColor.Light,
        isRemember: false,
        isRTL: Boolean(localStorage.getItem("rtl")),
    };
    useEffect(() => {
        let classList = document.body.classList;
        if (classList.length === 0 && state.themeColor === ThemeColor.Dark) {
            document.body.classList.add("dark-skin");
        }
    }, [state.themeColor])

    return (
        <div className="chats-tab-open h-100">
            <div className={"main-layout h-100"}>
                <NavBar activeMenu="complaints"/>
                <ComplaintsList/>
            </div>
        </div>
    );
}
