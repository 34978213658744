import React from "react";
import { Link } from "react-router-dom";
// import { ReactComponent as AppIconSvg } from "../../assets/media/icons/appicon.svg";
import { ReactComponent as ChatsSvg } from "../../assets/media/icons/chats.svg";
import { ReactComponent as CallsSvg } from "../../assets/media/icons/calls.svg";
import { ReactComponent as FriendsSvg } from "../../assets/media/icons/friends.svg";
import { ReactComponent as DocsSvg} from "../../assets/media/icons/profiledocuments.svg";
import { ReactComponent as InfoSvg} from "../../assets/media/icons/infosvg.svg";
import { ReactComponent as NotificationsSvg} from "../../assets/media/icons/question.svg";
// import { ReactComponent as AccountSvg } from "../../assets/media/icons/accounts.svg";
// import { ReactComponent as AccountSvg } from "../../assets/media/icons/accounts.svg";
import flogo from '../../assets/media/flogo.svg'
import logout from '../../assets/media/icons/logout.svg'
import {useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {logOutTC} from "../../Redux/auth-reducer";
import Radium, { StyleRoot } from 'radium';

function NavBar(props) {
    const history = useHistory();
    const dispatch = useDispatch()
    const logOut = () => {
        localStorage.removeItem("tokenKey");
        localStorage.removeItem("tokenDieTime");
        history.push("/SignIn")
        dispatch(logOutTC())
    }

    const navbarStyle = {
        width: '40px',
        '@media (maxWidth: 1199px)': {
            width: '25px',
        },
    }

  return (
      // <StyleRoot>
    <div className="navigation navbar navbar-light bg-primary">
      <Link className="d-none d-xl-block bg-light rounded p-1" to="/">
        {/*<AppIconSvg />*/}
          <img src={flogo} style={navbarStyle} alt=""/>
      </Link>

      <ul
        className="nav nav-minimal flex-row flex-grow-1 justify-content-between flex-xl-column justify-content-xl-center"
        id="mainNavTab"
        role="tablist"
        // style={{justifyContent: 'space-around', paddingLeft: 50, paddingRight: 50}}
      >
        <li className="nav-item">
          <Link
            className={
              "nav-link p-0 py-xl-3 " +
              (props.activeMenu === "chat" ? "active" : "")
            }
            id="chats-tab"
            to="/"
            title="Chats"
          >
            <ChatsSvg />
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              "nav-link p-0 py-xl-3 " +
              (props.activeMenu === "business" ? "active" : "")
            }
            id="business-tab"
            to="/Business"
            title="Business"
          >
            <DocsSvg />
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              "nav-link p-0 py-xl-3 " +
              (props.activeMenu === "users" ? "active" : "")
            }
            id="users-tab"
            to="/Users"
            title="Users"
          >
            <FriendsSvg />
          </Link>
        </li>
          <li className="nav-item">
              <Link
                  className={
                      "nav-link p-0 py-xl-3 " +
                      (props.activeMenu === "complaints" ? "active" : "")
                  }
                  id="complaints-tab"
                  to="/Complaints"
                  title="Complaints"
              >
                  <InfoSvg />
              </Link>
          </li>
          <li className="nav-item">
              <Link
                  className={
                      "nav-link p-0 py-xl-3 " +
                      (props.activeMenu === "transactions" ? "active" : "")
                  }
                  id="transactions-tab"
                  to="/Transactions"
                  title="Transactions"
              >
                  <svg id="Layer_1" data-name="Layer 1" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 72.15">
                      <path className="cls-1"
                            d="M94.81,26.32,61.3,61.37,42.73,44.12l1.92-2.69L58.25,54l.17.16a3.18,3.18,0,0,0,4.49-.21L64,52.71l0,0L69.17,47,92,23.72l2.81,2.6ZM96.2,43.78V37a1,1,0,0,1,0-.17c.12-3,2.13-4,4.84-3.13a1.47,1.47,0,0,1,.41.22c6.84,5.37,13.21,10.73,20,16.1l.09.09a3.17,3.17,0,0,1,1.21,3.18A4.49,4.49,0,0,1,121,55.74L102.62,70.62C99.38,73.17,96,72.69,96,68c0-2.09,0-4.2,0-6.29H69.9l17.18-18ZM30.53,33.06c0,1.64.15,3.66,0,5.26a7,7,0,0,1-.34,1.8,4.8,4.8,0,0,1-1.91,2.56,5,5,0,0,1-3,.77A7.8,7.8,0,0,1,23.16,43h0a3.57,3.57,0,0,1-.51-.22,4.6,4.6,0,0,1-.46-.3h0c-3.29-2.58-6.7-5.36-10.12-8.13-3.18-2.59-6.37-5.18-9.92-8l-.2-.18A5.83,5.83,0,0,1,.27,23.88l-.06-.2a4.82,4.82,0,0,1-.09-2.47,5.43,5.43,0,0,1,1-2.05,8.18,8.18,0,0,1,1.49-1.51C8.79,13,14.82,7.58,21,2.78A8.12,8.12,0,0,1,25.84.83a4.74,4.74,0,0,1,2,.42,4.44,4.44,0,0,1,1.64,1.29,6.87,6.87,0,0,1,1.26,4.33h0v4.36H55.63l-3.69,3.86H28.76a1.92,1.92,0,0,1-1.9-1.95c0-1.44,0-2.9,0-4.34V6.87h0a3.2,3.2,0,0,0-.44-1.95.67.67,0,0,0-.21-.18.92.92,0,0,0-.37-.06,4.37,4.37,0,0,0-2.47,1.13L5,20.68a4.07,4.07,0,0,0-.78.77,1.57,1.57,0,0,0-.3.59,1,1,0,0,0,0,.46l0,.09a2.24,2.24,0,0,0,.6.8c3.24,2.54,6.61,5.27,10,8s6.43,5.23,10,8a4.05,4.05,0,0,0,.94.18,1.27,1.27,0,0,0,.75-.16,1,1,0,0,0,.38-.55,3.31,3.31,0,0,0,.14-.77v-7A1.92,1.92,0,0,1,28.6,29.2h9.85l-3.53,3.7-.14.16ZM93.89,17.25,60.39,52.3,41.82,35.05,75.33,0,93.89,17.25Zm-21.15,7.4a5.76,5.76,0,1,1-7.37-3.47,5.76,5.76,0,0,1,7.37,3.47Zm12.78-4L63.13,43.67a3.75,3.75,0,0,0-5.29.2L50.5,37.06a3.75,3.75,0,0,0-.2-5.29L72.69,8.68A3.74,3.74,0,0,0,78,8.48l7.35,6.82a3.76,3.76,0,0,0,.19,5.3Z"/>
                  </svg>
              </Link>
          </li>
          <Link className={'d-xl-none'} to="/SignIn" onClick={logOut}>
              <img src={logout} alt=""/>
          </Link>
      </ul>
        <Link className={'d-none d-xl-block'} to="/SignIn" onClick={logOut}>
            <img src={logout} alt=""/>
        </Link>
    </div>
      /*</StyleRoot>*/
  );
}

// export default  Radium(NavBar);
export default  NavBar;
