import React, {useEffect, useRef, useState} from "react";


import LightGallery from 'lightgallery/react';
import {LightgalleryItem, LightgalleryProvider} from "react-lightgallery";
import lgRotate from 'lightgallery/plugins/rotate';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import {Link} from "react-router-dom";
import {ReactComponent as BackToList} from "../../../assets/media/icons/backtolist.svg";
import {ReactComponent as SearchSvg} from "../../../assets/media/icons/search.svg";
import {ReactComponent as InfoSvg} from "../../../assets/media/icons/infosvg.svg";
import {ReactComponent as ChatDocFileSvg} from "../../../assets/media/icons/chatdocmessage.svg";
import {ReactComponent as ChatPlusSvg} from "../../../assets/media/icons/chatplus.svg";
import {ReactComponent as EmojiSvg} from "../../../assets/media/icons/emoji.svg";
import {ReactComponent as GallarySvg} from "../../../assets/media/icons/gallary.svg";
import {ReactComponent as AudioSvg} from "../../../assets/media/icons/audio.svg";
import {ReactComponent as ContactSvg} from "../../../assets/media/icons/contact.svg";
import {ReactComponent as LocationSvg} from "../../../assets/media/icons/location.svg";
import {ReactComponent as PollSvg} from "../../../assets/media/icons/poll.svg";
import {ReactComponent as SendMessageSvg} from "../../../assets/media/icons/sendmessage.svg";
import flogo from '../../../assets/media/flogo.svg'
import pencil from '../../../assets/media/icons/pencil.svg'
import { ReactComponent as EditSvg } from "../../../assets/media/icons/edit.svg";
import {Dropdown} from "react-bootstrap";
import MessageDropdown from "./Dropdowns/MessageDropdown";

import "lightgallery.js/dist/css/lightgallery.css";

import "emoji-mart/css/emoji-mart.css";
import {Picker} from "emoji-mart";
import "../Chats.css";
import {useDispatch, useSelector} from "react-redux";
import {
    createNewChatTC, editMessageAC,
    fetchMoreMessages,
    getMessagesTC,
    getMoreMessagesTC,
    sentMessageTC
} from "../../../Redux/messages-reducer";
import {useHistory, useLocation} from "react-router";
import moment from "moment";
import {
    getProfileInfoTC,
    getUsersTC,
    setBusinessDocsStatusTC,
    setCurrentUserTC,
    setUserIsOnline
} from "../../../Redux/users-reducer";
import InfiniteScroll from "react-infinite-scroll-component";
import uuid from 'react-uuid'
import {ReactComponent as CloseSvg} from "../../../assets/media/icons/close.svg";
import photo1 from "../../../assets/media/shared-photos/01.jpg";
import Avatar from "react-avatar";
import business from "../../../assets/media/icons/business.svg"
import {API} from "../../../api/api";

// User  chat detail component - chat with users
export const UserChatFunc = React.memo((props) => {
        // debugger
        const key = localStorage.getItem("tokenKey")
        const dispatch = useDispatch()
        const messages = useSelector(state => state.messages.messages)
        const messagesIsLoad = useSelector(state => state.messages.messagesIsLoad)
        const currentUser = useSelector(state => state.usersPage.currentUser)
        const currentUserObj = useSelector(state => state.messages.users)
        const allUsers = useSelector(state => state.usersPage.users)
        const newChatUser = useSelector(state => state.usersPage.newChatUser)
        const selectNewChatType = useSelector(state => state.messages.selectNewChatType)
        const onlineStatus = useSelector(state => state.usersPage.userIsOnline)
        const location = useLocation();
        const endChatRef = useRef()
        const [state, setState] = useState(0);
        const [stateBD, setStateBD] = useState('unknown')
        const path = location.pathname.split('/')
        const history = useHistory()
        // console.log(onlineStatus)
        const scrollToLastMessage = () => {
            endChatRef.current.scrollIntoView({behavior: 'smooth'})
        }
        // useEffect(() => {
        //     dispatch(getProfileInfoTC(key, currentUser))
        // }, [currentUser])

        // useEffect(() => {
        //     dispatch(getMessagesTC(path[2], key, 5))
        // }, [path[2], key, messages.length])

        useEffect(() => {
            scrollToLastMessage()
        }, [])

        useEffect(() => {
            for (let i = 0; i < allUsers.length; i++) {
                if (allUsers[i].chatId === path[2].toString()) {
                    setState(1)
                    dispatch(setCurrentUserTC(allUsers[i].creatorId))
                }
            }
            scrollToLastMessage()
        }, [currentUser, newChatUser?.hashUser])

        useEffect(() => {
            // console.log(newChatUser.hashUser)
            newChatUser?.hashUser && dispatch(setCurrentUserTC(newChatUser.hashUser))
        }, [newChatUser?.hashUser])


        let [title, setTitle] = useState('')
        const onChangeHandler = (e) => {
            setTitle(e.target.value)
            if (editMessage != '') {
                setAfterEditMessage(e.target.value)
            }
        }
        const sendHandler = () => {
            if (title.trim() !== '') {
                dispatch(sentMessageTC(path[2], key, title))
                setTitle('');
            } else {
                if (newChatUser.supportChatId !== 0 && selectNewChatType === 'support' && path[2] === newChatUser.supportChatId) {
                    dispatch(sentMessageTC(newChatUser.supportChatId, key, title))
                }
            }
            if (newChatUser.creatorChatId === 0 && selectNewChatType === 'creator' && path[2] === 'new') {
                dispatch(createNewChatTC(key, newChatUser.hashUser, selectNewChatType, title, history.push))
                dispatch(getProfileInfoTC(key, newChatUser.hashUser))
            }
            if (newChatUser.supportChatId == 0 && selectNewChatType === 'support' && path[2] === 'new') {
                dispatch(createNewChatTC(key, newChatUser.hashUser, selectNewChatType, title, history.push))
                dispatch(getProfileInfoTC(key, newChatUser.hashUser))
            }
            // сделать получше проверку
        }
        const onKeyPressHandler = (e) => {
            if (e.charCode === 13 && afterEditMessage === '') {
                sendHandler();
            }
            if (e.charCode === 13 && afterEditMessage !== '') {
                editHandler();
            }
        }

        const [editMessage, setEditMessage] = useState("");
        const [afterEditMessage, setAfterEditMessage] = useState("");
        const [editMessageId, setEditMessageId] = useState("");
        const editFunc = (message, id) => {
            setEditMessage(message)
            setTitle(message)
            setAfterEditMessage(message)
            setEditMessageId(id)
        }
        const editHandler = () => {
            if(editMessage === afterEditMessage) {
                funcClearStateMessages()
                return
            }
            API.editMessage(key, editMessageId, afterEditMessage)
                .then((res) => {
                    setTitle('')
                    setEditMessage('')
                    setAfterEditMessage('')
                    if (afterEditMessage !== '') {
                        dispatch(editMessageAC(editMessageId, afterEditMessage, '1'))
                    }
                })
        }
        const funcClearStateMessages = () => {
            setTitle('');
            setEditMessage('')
            setAfterEditMessage('')
        }

        useEffect(() => {
            const interval = setInterval(() => {
                dispatch(getMessagesTC(path[2], key, 5))
            }, 1000);
            return () => clearInterval(interval);
        }, [path[2], key, messages.length])

        useEffect(() => {
            funcClearStateMessages()
        }, [path[2]])

        const fetchMoreMessagess = () => {
            if (messages.length > 0) {
                let lastKey = Object.keys(messages).pop()
                dispatch(getMoreMessagesTC(path[2], key, 5, messages[lastKey].id))
            }
        }

        const [profileDetails, setProfileDetails] = useState(false);
        const showProfileInfo = () => {
            setProfileDetails(!profileDetails)
        }

    const profileInfo = useSelector(state => state.usersPage.profileInfo)
    const hashCurrentUser = useSelector(state => state.usersPage.currentUser)
    const businessDocsStatusRedux = useSelector(state => state.usersPage.businesDocStatus)
    useEffect(() => {
        dispatch(getProfileInfoTC(key, hashCurrentUser != '' && hashCurrentUser))
    }, [hashCurrentUser])

    const avaPath = () => {
        let newAvatarPath
        if (currentUser !== '' && Object.keys(currentUserObj).length > 0) {
            newAvatarPath = currentUserObj?.[currentUser]?.avatar.split('.')
            return 'https://cdn.fanter.com/' + newAvatarPath?.[0] + '-m.' + newAvatarPath?.[1]
        }
    }

    const moreAvaPath = () => {
        let newAvatarPathFromInfo
        if (profileInfo !== undefined && Object.keys(profileInfo).length > 0) {
            newAvatarPathFromInfo = profileInfo?.avatar.split('.')
            return 'https://cdn.fanter.' + newAvatarPathFromInfo?.[2] + '-m.' + newAvatarPathFromInfo?.[3]
        }
    }
    useEffect(() => {
        dispatch(setUserIsOnline(currentUser))
    }, [currentUser])

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(setUserIsOnline(currentUser))
        }, 5000);
        return () => clearInterval(interval);
    }, [onlineStatus])


    const confirmDocumentsAndBA = (status) => {
        dispatch(setBusinessDocsStatusTC(key, currentUser, status))
    }


    const resetFraud = () => {
        API.setFraud(key, currentUser, '0')
            .then((res) => {
                dispatch(getProfileInfoTC(key, currentUser))
            })
    }


    // useEffect(() => {
    //     API.editMessage(key, "29951", 'heyEdit!2')
    //         .then((res) => {
    //             debugger
    //         })
    // }, [])
        return (
            <main className={"main main-visible"}>
                <div className="chats">
                    <div className="chat-body" style={{position: 'relative'}}>
                        <div className="chat-header" style={{height: 73}}>
                            <Link className="contacts-link" to="/Chats">
                                <button
                                    className="btn btn-secondary btn-icon btn-minimal btn-sm text-muted d-xl-none"
                                    type="button"
                                >
                                    <BackToList/>
                                </button>
                            </Link>
                            {
                                (currentUserObj?.[currentUser] || profileInfo) &&
                                <div className="media chat-name align-items-center text-truncate">
                                    {/*avatar-online добавить класс, если онлайн*/}
                                    <div className={"avatar d-sm-inline-block mr-3" + (onlineStatus?.flagOnline === 1 ? " avatar-online" : onlineStatus?.flagOnline === 2 ? " avatar-away" : "")}>
                                        <Link to={'/Users/' + currentUser}>
                                            {
                                                state === 1 && currentUserObj[currentUser] !== undefined ?
                                                    <Avatar
                                                        style={{minWidth: 48, objectFit: 'cover'}}
                                                        name={currentUserObj?.[currentUser]?.firstName + ' ' + currentUserObj?.[currentUser]?.lastName + ' ' + currentUserObj?.[currentUser]?.nickname}
                                                        src={avaPath()}
                                                        size={48}
                                                        round
                                                    /> :
                                                    <Avatar
                                                        style={{minWidth: 48, objectFit: 'cover'}}
                                                        size={48}
                                                        src={moreAvaPath()}
                                                        name={currentUserObj?.[currentUser]?.firstName + ' ' + currentUserObj?.[currentUser]?.lastName + ' ' + currentUserObj[currentUser]?.nickname}
                                                        round
                                                    />

                                            }
                                        </Link>
                                    </div>
                                    <div className="media-body align-self-center ">
                                        <h6 className="text-truncate mb-0">
                                            <Link to={'/Users/' + currentUser} style={{color: '#fff', display: 'inline-block'}}>
                                                {
                                                    state === 1 && currentUserObj?.[currentUser] !== undefined ?
                                                        currentUserObj[currentUser]?.firstName + ' ' +
                                                        currentUserObj[currentUser]?.lastName + ' ( @' +
                                                        currentUserObj[currentUser]?.nickname + ' )'
                                                        :
                                                        profileInfo?.firstName + ' ' +
                                                        profileInfo?.lastName + ' ( @' +
                                                        profileInfo?.nickname + ' )'
                                                }
                                                {
                                                    profileInfo?.isBusiness === 1 && <img style={{marginLeft: 7, width: 20}} src={business} alt=""/>
                                                }
                                            </Link>
                                        </h6>
                                        <small className="text-muted">
                                            { onlineStatus?.flagOnline === 1 && 'Online' }
                                            { onlineStatus?.flagOnline === 2 && 'Away' }
                                            { onlineStatus?.flagOnline === 3 && 'Was less than an hour ago' }
                                            { onlineStatus?.flagOnline === 4 && 'Was ' + onlineStatus?.flagOnlineDetail + ' hours ago' }
                                            { onlineStatus?.flagOnline === 5 && 'Was ' + onlineStatus?.flagOnlineDetail + ' hours ago' }
                                            { onlineStatus?.flagOnline === 6 && 'Was ' + onlineStatus?.flagOnlineDetail + ' hours ago' }
                                            { onlineStatus?.flagOnline === 7 && 'Was ' + onlineStatus?.flagOnlineDetail + ' days ago' }
                                            { onlineStatus?.flagOnline === 8 && 'Was ' + onlineStatus?.flagOnlineDetail + ' weeks ago' }
                                            { onlineStatus?.flagOnline === 9 && 'Was a long time ago' }
                                        </small>
                                    </div>
                                </div>
                            }

                            <ul className="nav flex-nowrap">
                                <Link
                                    className="d-flex"
                                    to="#"
                                    onClick={showProfileInfo}
                                >
                                    <InfoSvg style={{color: "#adb5bd"}} className="hw-20"/>
                                </Link>
                            </ul>
                        </div>
                        <div
                            className={
                                "border-bottom px-3 collapse "
                            }
                        >
                            <div className="container-xl py-2 px-0 px-md-3">
                                <div className="input-group bg-light ">
                                    <input
                                        type="text"
                                        className="form-control form-control-md border-right-0 transparent-bg pr-0"
                                        placeholder="Search..."
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text transparent-bg border-left-0">
                                          <SearchSvg className="hw-20 text-muted"/>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="chat-content p-3" id="overMess"
                             style={{height: '100%', overflow: "auto", display: 'flex', flexDirection: 'column-reverse',}}>
                            <div ref={endChatRef} className="chat-finished" id="chat-finished"/>
                            <InfiniteScroll
                                dataLength={messages.length}
                                next={fetchMoreMessagess}
                                style={{display: "flex", flexDirection: "column-reverse"}}
                                inverse
                                hasMore
                                loader={<h6>Loading more messages...</h6>}
                                scrollableTarget="overMess"
                            >
                                {
                                    messages && messages.map((value, mess, elements) => {
                                        const nextMessageAfterFirst = elements[mess + 1];
                                        const countChatMessages = messages.length;
                                        // let photoPath = value.message
                                        return (

                                            <div key={uuid()}>
                                                {
                                                    countChatMessages === mess + 1 && <div className="message-divider pb-2"
                                                                                           data-label={moment.unix(elements[mess].tStamp).format("LL")}/>
                                                }
                                                {
                                                    nextMessageAfterFirst && (moment.unix(nextMessageAfterFirst.tStamp).format("LL") !== moment.unix(value.tStamp).format("LL")) &&
                                                    <div className="message-divider pb-2"
                                                         data-label={moment.unix(value.tStamp).format("LL")}/>
                                                }

                                                <div
                                                    className={value.creatorId === currentUser ? 'message' : 'message self'}>
                                                    <div className="message-wrapper">
                                                        <div className="message-content">
                                                            {
                                                                value.messageType === "7" ?
                                                                <div className="form-row">
                                                                    <LightgalleryProvider mode={'lg-rotate'} className={'lg-rotate'}>
                                                                        <div className="col" style={{width: 250}}>
                                                                            <LightgalleryItem
                                                                                group={"group1"}
                                                                                src={'https://cdn.fanter.com/' + value.message}
                                                                                thumb={photo1}
                                                                            >
                                                                                <img
                                                                                    alt="video?"
                                                                                    src={'https://cdn.fanter.com/' + value.message}
                                                                                    style={{ width: "100%" }}
                                                                                />
                                                                            </LightgalleryItem>
                                                                        </div>
                                                                    </LightgalleryProvider>
                                                                </div> :
                                                                <span>
                                                                    {value.message}
                                                                </span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="message-options">
                                                        <div className="avatar avatar-sm">
                                                            {
                                                                value.creatorId === currentUser ?
                                                                <Avatar
                                                                    style={{minWidth: 36, objectFit: 'cover', boxShadow: '0 0 0 0.5rem #323333'}}
                                                                    name={currentUserObj?.[currentUser]?.firstName + ' ' + currentUserObj?.[currentUser]?.lastName + ' ' + currentUserObj?.[currentUser]?.nickname}
                                                                    // src={'https://cdn.fanter.com/' + currentUserObj?.[currentUser]?.avatar}
                                                                    src={avaPath()}
                                                                    size={36}
                                                                    round
                                                                /> :
                                                                    <img src={flogo} alt=""/>
                                                            }
                                                            {/*<img alt="" src={value.creatorId === currentUser ? 'https://cdn.fanter.com/' + currentUserObj[currentUser].avatar : flogo}/>*/}
                                                        </div>
                                                        <span className="message-date">
                                                            {
                                                                value.creatorId !== currentUser ? (value.opponentReaded === '1' ?
                                                                    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path opacity="0.9" d="M7.75 6.0625L9.68207 7.30978L14.5 1" stroke="#CFCFDD" strokeWidth="1.6875" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                        <path opacity="0.9" d="M1 4.9375L4.05707 7.30978L8.875 1" stroke="#CFCFDD" strokeWidth="1.6875" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                    </svg> :
                                                                    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path opacity="0.9" d="M1.75 6.0625L3.68207 7.30978L8.5 1" stroke="#CFCFDD" strokeWidth="1.6875" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                    </svg>) : null
                                                            }

                                                            {' ' + moment.unix(value.tStamp).format("HH:mm")}
                                                        </span>
                                                        {value.creatorId != currentUser && <MessageDropdown func={editFunc} messageInfo={value} />}
                                                        {value.isEdit === '1' && <EditSvg style={{width: 12}}/>}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </InfiniteScroll>
                        </div>
                        {
                            editMessage != '' &&
                            <div style={{position: 'absolute', borderTop: '1px solid #665dfe', bottom: 79, width: '100%', height: 45, display: 'flex', alignItems: 'center', background: '#323333'}}>
                                <div style={{dispkay: 'inline-block',marginLeft: 2.25 + 'rem'}}><EditSvg fill={"white"} stroke={'#adb5bd'}/></div>
                                <div style={{ borderLeft: '3px solid #665dfe', height: '100%', width: '100%', paddingLeft: 10, display: 'flex', flexDirection: 'column'}}>
                                    <div style={{color: '#adb5bd', fontSize: 13, paddingTop: 2}}>Редактирование</div>
                                    <div style={{color: 'white', fontSize: 14, marginTop: -4, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 'calc(100% - 150px)'}}>
                                        {editMessage}
                                    </div>
                                    <span style={{position: 'absolute', right: 52, top: 8, cursor: 'pointer'}} onClick={funcClearStateMessages}><CloseSvg stroke={'#adb5bd'}/></span>
                                </div>
                            </div>
                        }

                        <div className="chat-footer">
                            <div className="form-row align-items-center">
                                <div className="col">
                                    <div className="input-group">
                                        <div className="input-group-prepend mr-sm-2 mr-1">
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    className="text-muted hw-20"
                                                    as={ChatPlusSvg}
                                                />
                                                <Dropdown.Menu className="dropdown-menu">
                                                    <Link className="dropdown-item" to="#">
                                                        <GallarySvg className="hw-20 mr-2"/>
                                                        <span>Gallery</span>
                                                    </Link>
                                                    <Link className="dropdown-item" to="#">
                                                        <AudioSvg/>
                                                        <span>Audio</span>
                                                    </Link>
                                                    <Link className="dropdown-item" to="#">
                                                        <ChatDocFileSvg className="hw-20 mr-2"/>
                                                        <span>Document</span>
                                                    </Link>
                                                    <Link className="dropdown-item" to="#">
                                                        <ContactSvg/>
                                                        <span>Contact</span>
                                                    </Link>
                                                    <Link className="dropdown-item" to="#">
                                                        <LocationSvg/>
                                                        <span>Location</span>
                                                    </Link>
                                                    <Link className="dropdown-item" to="#">
                                                        <PollSvg/>
                                                        <span>Poll</span>
                                                    </Link>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control transparent-bg border-0 no-resize hide-scrollbar"
                                            placeholder="Write your message..."
                                            rows="1"
                                            onChange={onChangeHandler}
                                            value={title}
                                            onKeyPress={onKeyPressHandler}
                                        />
                                        <div className="input-group-prepend mr-sm-2 mr-1">
                                            <Dropdown className="w-100">
                                                <Dropdown.Toggle
                                                    className="text-muted hw-20"
                                                    as={EmojiSvg}
                                                />
                                                <Dropdown.Menu className="border-0 color-none">
                                                    <Picker
                                                        className="emoji-picker"
                                                        title="Pick your emoji…"
                                                        emoji="point_up"
                                                        showPreview={true}
                                                        set="facebook"
                                                    />
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div
                                        className="btn btn-primary btn-icon rounded-circle text-light mb-1"
                                        role="button"
                                        onClick={
                                            editMessage === '' ? sendHandler : editHandler
                                        }
                                        style={editMessage != '' ? {transform: 'rotate(0.25turn)'} : {transform: 'rotate(0.0turn)'}}
                                    >
                                        <SendMessageSvg/>
                                    </div>
                                </div>
                            </div>
                            {/*<div style={{display: 'flex'}}>*/}
                            {/*    <input*/}
                            {/*        type="text"*/}
                            {/*        className="form-control transparent-bg border-0 no-resize hide-scrollbar"*/}
                            {/*        placeholder="Edit your message..."*/}
                            {/*        rows="1"*/}
                            {/*        // onChange={}*/}
                            {/*        // value={}*/}
                            {/*        // onKeyPress={}*/}
                            {/*    />*/}
                            {/*    <div*/}
                            {/*        className="btn btn-primary btn-icon rounded-circle text-light mb-1"*/}
                            {/*        role="button"*/}
                            {/*        onClick={editHandler}*/}
                            {/*        style={{transform: 'rotate(0.25turn)'}}*/}
                            {/*    >*/}
                            {/*        <SendMessageSvg/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    <div className={
                        "chat-info " + (profileDetails ?
                        "chat-info-visible" : '')
                    }>
                        <div className="d-flex h-100 flex-column">
                            <div className="chat-info-header px-2">
                                <div className="container-fluid">
                                    <ul className="nav justify-content-between align-items-center">
                                        <li className="text-center">
                                            <h5 className="text-truncate mb-0">Profile Details</h5>
                                        </li>
                                        <li className="nav-item list-inline-item">
                                            <Link
                                                className="nav-link text-muted px-0"
                                                to="#"
                                                onClick={showProfileInfo}
                                            >
                                                <CloseSvg/>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="hide-scrollbar flex-fill">
                                <div className="text-center p-3">
                                    <div className="avatar avatar-xl mx-5 mb-3">
                                        {/*<img className="avatar-img" src={avatar2} alt=""></img>*/}
                                        {
                                            profileInfo &&
                                            <Avatar
                                                size={120}
                                                // src={profileInfo.avatar}
                                                src={moreAvaPath()}
                                                name={currentUserObj?.[currentUser]?.firstName + ' ' + currentUserObj?.[currentUser]?.lastName + ' ' + currentUserObj[currentUser]?.nickname}
                                                round
                                            />
                                        }
                                    </div>
                                    <h5 className="mb-1">
                                        {/*Catherine Richardson*/}
                                        {
                                            profileInfo && profileInfo.firstName + " " + profileInfo.lastName
                                        }
                                        {
                                            profileInfo && profileInfo.firstName === "" && profileInfo.lastName === '' && profileInfo.nickname
                                        }
                                    </h5>
                                    {/*<p className="text-muted d-flex align-items-center justify-content-center">*/}
                                    {/*    <LocationSvg/>*/}
                                    {/*    <span>San Fransisco, CA</span>*/}
                                    {/*</p>*/}

                                    {/*<div className="d-flex align-items-center justify-content-center">*/}
                                    {/*    <div className="btn btn-outline-default btn-icon rounded-circle mx-1">*/}
                                    {/*        <AddContactSvg/>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="btn btn-primary btn-icon rounded-circle text-light mx-1">*/}
                                    {/*        <HeartSvg/>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="btn btn-danger btn-icon rounded-circle text-light mx-1">*/}
                                    {/*        <BlockSvg/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="chat-info-group">
                                    <Link
                                        className="chat-info-group-header"
                                        data-toggle="collapse"
                                        to="#"
                                        // onClick={this.toggleShowUserInformation}
                                    >
                                        <h6 className="mb-0">User Information</h6>
                                        <InfoSvg className="hw-20 text-muted"/>
                                    </Link>

                                    <div
                                        className={
                                            "chat-info-group-body collapse show"
                                            //+ (this.state.showUserInformation ? "show" : "")
                                        }
                                    >
                                        <div className="chat-info-group-content list-item-has-padding">
                                            <ul className="list-group list-group-flush ">
                                                <li className="list-group-item border-0">
                                                    <p className="small text-muted mb-0">Nickname</p>
                                                    <p className="mb-0">
                                                        {profileInfo !== undefined && '@'+ profileInfo.nickname}
                                                    </p>
                                                </li>
                                                <li className="list-group-item border-0">
                                                    <p className="small text-muted mb-0">Email</p>
                                                    <p className="mb-0">
                                                        {profileInfo !== undefined && profileInfo.email}
                                                    </p>
                                                </li>
                                                <li className="list-group-item border-0">
                                                    <p className="small text-muted mb-0">Invite code</p>
                                                    <p className="mb-0">
                                                        {profileInfo !== undefined && profileInfo.invite}
                                                    </p>
                                                </li>
                                                <li className="list-group-item border-0">
                                                    <p className="small text-muted mb-0">Reg invite code</p>
                                                    <p className="mb-0">
                                                        {profileInfo !== undefined && profileInfo.regInvite}
                                                    </p>
                                                </li>
                                                <li className="list-group-item border-0">
                                                    <p className="small text-muted mb-0">Withdraw</p>
                                                    <p className="mb-0">
                                                        {profileInfo !== undefined && profileInfo.withdrawAmount}
                                                    </p>
                                                </li>
                                                <li className="list-group-item border-0">
                                                    <p className="small text-muted mb-0">Комиссия фантера</p>
                                                    <p className="mb-0">
                                                        {profileInfo?.fComission}%
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="chat-info-group">
                                    <Link
                                        className="chat-info-group-header"
                                        data-toggle="collapse"
                                        to="#"
                                        // onClick={this.toggleShowLastMedia}
                                    >
                                        <h6 className="mb-0">Business Account</h6>
                                        <GallarySvg className="hw-20 text-muted"/>
                                    </Link>

                                    <div
                                        className={
                                            "chat-info-group-body collapse show"
                                            // + (this.state.showLastMedia ? "show" : "")
                                        }
                                        id="shared-media"
                                    >
                                        <ul className="list-group list-group-flush " style={{paddingLeft: 7}}>
                                            <li className="list-group-item border-0" style={{paddingTop: 0}}>
                                                <p className="small text-muted mb-0">Status</p>
                                                <p className="mb-0">
                                                    {profileInfo !== undefined && profileInfo.isBusiness === 1 ? 'True' : 'False'}
                                                </p>
                                            </li>
                                            <li className="list-group-item border-0" style={{paddingTop: 0}}>
                                                <p className="small text-muted mb-0">Fraud Score</p>
                                                <p className="mb-0" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                                    <span>
                                                        {profileInfo !== undefined && profileInfo.fraudScore}
                                                    </span>
                                                    <button
                                                        className="btn btn-primary no-box-shadow"
                                                        type="button"
                                                        onClick={resetFraud}
                                                    >
                                                        Reset
                                                    </button>
                                                </p>
                                            </li>
                                        </ul>

                                        <div style={{display: "flex", padding: '0 25px'}}>
                                            <div className="form-group rounded"
                                                 style={{marginRight: 10, marginBottom: 0, marginTop: 5}}>
                                                <div className="custom-control custom-radio">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="exampleRadios"
                                                        value="option1"
                                                        checked={businessDocsStatusRedux === 2}
                                                        onChange={() => {

                                                        }}
                                                        onClick={() => {
                                                            confirmDocumentsAndBA(2)
                                                            setStateBD('2')
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        onClick={() => {
                                                            confirmDocumentsAndBA(2)
                                                            setStateBD('2')
                                                        }}
                                                    >
                                                        On check
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group rounded"
                                                 style={{marginRight: 10, marginBottom: 0, marginTop: 5}}>
                                                <div className="custom-control custom-radio">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="exampleRadios"
                                                        value={'option2'}
                                                        checked={businessDocsStatusRedux === 4}
                                                        onClick={() => {
                                                            confirmDocumentsAndBA(4)
                                                            setStateBD('4')
                                                        }}
                                                        onChange={() => {

                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        onClick={() => {
                                                            confirmDocumentsAndBA(4)
                                                            setStateBD('4')
                                                        }}
                                                    >
                                                        Rejected
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group rounded"
                                                 style={{marginRight: 10, marginBottom: 0, marginTop: 5}}>
                                                <div className="custom-control custom-radio">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="exampleRadios"
                                                        value="option2"
                                                        checked={businessDocsStatusRedux === 3}
                                                        onChange={() => {

                                                        }}
                                                        onClick={() => {
                                                            confirmDocumentsAndBA(3)
                                                            setStateBD('3')
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        onClick={() => {
                                                            confirmDocumentsAndBA(3)
                                                            setStateBD('3')
                                                        }}
                                                    >
                                                        Сonfirmed
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="chat-info-group-content">

                                            <div className="form-row">
                                                <p className="mb-1" style={{display: 'flex', paddingTop: 6}}>
                                                    <LightgalleryProvider
                                                        mode={"lg-rotate"}
                                                        plugins={[lgRotate]}
                                                        lightgallerySettings={
                                                            {

                                                                // rotate: true
                                                                // settings: https://sachinchoolur.github.io/lightgallery.js/docs/api.html
                                                            }
                                                        }
                                                    >

                                                        {

                                                            profileInfo !== undefined && profileInfo.bankDocList !== undefined && profileInfo.bankDocList.map((doc) => {
                                                                let path = 'https://cdn.fanter.com/' + doc.filePath
                                                                return (
                                                                    <LightgalleryItem
                                                                        group={"group1"}
                                                                        // key={path}
                                                                        src={path}
                                                                        thumb={path}
                                                                        key={uuid()}
                                                                    >
                                                                        <img
                                                                            alt="photo1"
                                                                            src={path}
                                                                            style={{
                                                                                width: "100px",
                                                                                height: '100%',
                                                                                objectFit: 'cover',
                                                                                marginRight: 10,
                                                                                borderRadius: 4
                                                                            }}
                                                                        />
                                                                    </LightgalleryItem>
                                                                )
                                                            })

                                                        }
                                                    </LightgalleryProvider>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="chat-info-group">
                                    <Link
                                        className="chat-info-group-header"
                                        data-toggle="collapse"
                                        to="#"
                                        // onClick={this.toggleShowUserInformation}
                                    >
                                        <h6 className="mb-0">Payments Information</h6>
                                        <InfoSvg className="hw-20 text-muted"/>
                                    </Link>

                                    <div
                                        className={
                                            "chat-info-group-body collapse show"
                                            //+ (this.state.showUserInformation ? "show" : "")
                                        }
                                    >
                                        <div className="chat-info-group-content list-item-has-padding">
                                            <ul className="list-group list-group-flush ">
                                                {/*<li className="list-group-item border-0">*/}
                                                {/*    <p className="small text-muted mb-0">Nickname</p>*/}
                                                {/*    <p className="mb-0">*/}
                                                {/*        {profileInfo !== undefined && '@'+ profileInfo.nickname}*/}
                                                {/*    </p>*/}
                                                {/*</li>*/}
                                                {
                                                    profileInfo.bankOptions != undefined && profileInfo.bankOptions.length > 0 && profileInfo.bankOptions[0].payOnCard === "0" &&
                                                    <>
                                                        <li className="list-group-item border-0">
                                                            <div className="media align-items-center">
                                                                <div className="media-body">
                                                                    <p className="small text-muted mb-0">id</p>
                                                                    <p className="mb-0">
                                                                        {profileInfo.bankOptions[0].id}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item border-0">
                                                            <div className="media align-items-center">
                                                                <div className="media-body">
                                                                    <p className="small text-muted mb-0">userId</p>
                                                                    <p className="mb-0">
                                                                        {profileInfo.bankOptions[0].userId}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item border-0">
                                                            <div className="media align-items-center">
                                                                <div className="media-body">
                                                                    <p className="small text-muted mb-0">fio</p>
                                                                    <p className="mb-0">
                                                                        {profileInfo.bankOptions[0].fio}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item border-0">
                                                            <div className="media align-items-center">
                                                                <div className="media-body">
                                                                    <p className="small text-muted mb-0">Банк</p>
                                                                    <p className="mb-0">
                                                                        {profileInfo.bankOptions[0].bank}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item border-0">
                                                            <div className="media align-items-center">
                                                                <div className="media-body">
                                                                    <p className="small text-muted mb-0">Номер счета</p>
                                                                    <p className="mb-0">
                                                                        {profileInfo.bankOptions[0].numberAccount}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item border-0">
                                                            <div className="media align-items-center">
                                                                <div className="media-body">
                                                                    <p className="small text-muted mb-0">Корр. счет</p>
                                                                    <p className="mb-0">
                                                                        {profileInfo.bankOptions[0].korrBank}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item border-0">
                                                            <div className="media align-items-center">
                                                                <div className="media-body">
                                                                    <p className="small text-muted mb-0">Бик</p>
                                                                    <p className="mb-0">
                                                                        {profileInfo.bankOptions[0].bikBank}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item border-0">
                                                            <div className="media align-items-center">
                                                                <div className="media-body">
                                                                    <p className="small text-muted mb-0">ИНН Банка</p>
                                                                    <p className="mb-0">
                                                                        {profileInfo.bankOptions[0].innBank}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item border-0">
                                                            <div className="media align-items-center">
                                                                <div className="media-body">
                                                                    <p className="small text-muted mb-0">КПП Банка</p>
                                                                    <p className="mb-0">
                                                                        {profileInfo.bankOptions[0].kppBank}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item border-0">
                                                            <div className="media align-items-center">
                                                                <div className="media-body">
                                                                    <p className="small text-muted mb-0">Дата рождения</p>
                                                                    <p className="mb-0">
                                                                        {profileInfo.bankOptions[0].birthDay}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item border-0">
                                                            <div className="media align-items-center">
                                                                <div className="media-body">
                                                                    <p className="small text-muted mb-0">Страна</p>
                                                                    <p className="mb-0">
                                                                        {profileInfo.bankOptions[0].country}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item border-0">
                                                            <div className="media align-items-center">
                                                                <div className="media-body">
                                                                    <p className="small text-muted mb-0">Город</p>
                                                                    <p className="mb-0">
                                                                        {profileInfo.bankOptions[0].town}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item border-0">
                                                            <div className="media align-items-center">
                                                                <div className="media-body">
                                                                    <p className="small text-muted mb-0">Адрес1</p>
                                                                    <p className="mb-0">
                                                                        {profileInfo.bankOptions[0].address1}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item border-0">
                                                            <div className="media align-items-center">
                                                                <div className="media-body">
                                                                    <p className="small text-muted mb-0">Адрес2</p>
                                                                    <p className="mb-0">
                                                                        {profileInfo.bankOptions[0].address2}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item border-0">
                                                            <div className="media align-items-center">
                                                                <div className="media-body">
                                                                    <p className="small text-muted mb-0">Индекс</p>
                                                                    <p className="mb-0">
                                                                        {profileInfo.bankOptions[0].zipCode}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item border-0">
                                                            <div className="media align-items-center">
                                                                <div className="media-body">
                                                                    <p className="small text-muted mb-0">Штат</p>
                                                                    <p className="mb-0">
                                                                        {profileInfo.bankOptions[0].addressState}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </>
                                                }
                                                {
                                                    profileInfo.bankOptions != undefined && profileInfo.bankOptions.length > 0 && profileInfo.bankOptions[0].payOnCard === "1" &&
                                                    <>
                                                        <li className="list-group-item border-0">
                                                            <div className="media align-items-center">
                                                                <div className="media-body">
                                                                    <p className="small text-muted mb-0">id</p>
                                                                    <p className="mb-0">
                                                                        {profileInfo.bankOptions[0].id}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item border-0">
                                                            <div className="media align-items-center">
                                                                <div className="media-body">
                                                                    <p className="small text-muted mb-0">userId</p>
                                                                    <p className="mb-0">
                                                                        {profileInfo.bankOptions[0].userId}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item border-0">
                                                            <div className="media align-items-center">
                                                                <div className="media-body">
                                                                    <p className="small text-muted mb-0">fio</p>
                                                                    <p className="mb-0">
                                                                        {profileInfo.bankOptions[0].fio}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item border-0">
                                                            <div className="media align-items-center">
                                                                <div className="media-body">
                                                                    <p className="small text-muted mb-0">Держатель карты</p>
                                                                    <p className="mb-0">
                                                                        {profileInfo.bankOptions[0].cardHolder}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item border-0">
                                                            <div className="media align-items-center">
                                                                <div className="media-body">
                                                                    <p className="small text-muted mb-0">Номер карты</p>
                                                                    <p className="mb-0">
                                                                        {profileInfo.bankOptions[0].cardNumber}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </>
                                                }

                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="chat-info-group">
                                    <Link
                                        className="chat-info-group-header"
                                        data-toggle="collapse"
                                        to="#"
                                        // onClick={this.toggleShowLastMedia}
                                    >
                                        <h6 className="mb-0">Social Networks</h6>
                                        <GallarySvg className="hw-20 text-muted"/>
                                    </Link>

                                    <div
                                        className={
                                            "chat-info-group-body collapse show"
                                            // + (this.state.showLastMedia ? "show" : "")
                                        }
                                        id="shared-media"
                                    >
                                        <ul className="list-group list-group-flush " style={{paddingLeft: 7}}>
                                            {
                                                profileInfo !== undefined && profileInfo.social?.facebook !== "" &&
                                                <li className="list-group-item border-0" style={{paddingTop: 0}}>
                                                    <p className="small text-muted mb-0">Facebook</p>
                                                    <p className="mb-0">
                                                        { profileInfo.social?.facebook}
                                                    </p>
                                                </li>
                                            }
                                            {
                                                profileInfo !== undefined && profileInfo.social?.google !== "" &&
                                                <li className="list-group-item border-0" style={{paddingTop: 0}}>
                                                    <p className="small text-muted mb-0">Google</p>
                                                    <p className="mb-0">
                                                        { profileInfo.social?.google}
                                                    </p>
                                                </li>
                                            }
                                            {
                                                profileInfo !== undefined && profileInfo.social?.instagram !== "" &&
                                                <li className="list-group-item border-0" style={{paddingTop: 0}}>
                                                    <p className="small text-muted mb-0">Instagram</p>
                                                    <p className="mb-0">
                                                        { profileInfo.social?.instagram}
                                                    </p>
                                                </li>
                                            }
                                            {
                                                profileInfo !== undefined && profileInfo.social?.tiktok !== "" &&
                                                <li className="list-group-item border-0" style={{paddingTop: 0}}>
                                                    <p className="small text-muted mb-0">TikTok</p>
                                                    <p className="mb-0">
                                                        { profileInfo.social?.tiktok}
                                                    </p>
                                                </li>
                                            }
                                            {
                                                profileInfo !== undefined && profileInfo.social?.twitter !== "" &&
                                                <li className="list-group-item border-0" style={{paddingTop: 0}}>
                                                    <p className="small text-muted mb-0">Twitter</p>
                                                    <p className="mb-0">
                                                        { profileInfo.social?.twitter}
                                                    </p>
                                                </li>
                                            }
                                            {
                                                profileInfo !== undefined && profileInfo.social?.youtube !== "" &&
                                                <li className="list-group-item border-0" style={{paddingTop: 0}}>
                                                    <p className="small text-muted mb-0">Youtube</p>
                                                    <p className="mb-0">
                                                        { profileInfo.social?.youtube}
                                                    </p>
                                                </li>
                                            }

                                        </ul>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
)
