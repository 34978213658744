import React, {useEffect} from "react";
import NavBar from "../NavBar/NavBar";
import {ChatListFunc} from "./ChatList/ChatListFunc";
import {ThemeColor} from "../../Config/Config";
import {ChatHistory} from "./ChatHistory/ChatHistory";
import {useLocation} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {getUsersTC, setCurrentUserTC} from "../../Redux/users-reducer";
import {API} from "../../api/api";
import {getMessagesTC} from "../../Redux/messages-reducer";
import {refreshTokenTC} from "../../Redux/auth-reducer";

// Chat master component
export const ChatsFunc = React.memo((props) => {
    const state = {
        themeColor: Boolean(localStorage.getItem("theme"))
            ? parseInt(localStorage.getItem("theme"))
            : ThemeColor.Light,
        isRemember: false,
        isRTL: Boolean(localStorage.getItem("rtl")),
    };
    useEffect(() => {
        let classList = document.body.classList;
        if (classList.length === 0 && state.themeColor === ThemeColor.Dark) {
            document.body.classList.add("dark-skin");
        }
    }, [state.themeColor])
    const location = useLocation();
    const path = location.pathname.split('/')
    const dispatch = useDispatch()
    const users = useSelector(state => state.usersPage.users)
    // const infoUsers = useSelector(state => state.usersPage.infoUsers)
    const filterChats = useSelector(state => state.usersPage.filterChats)
    const messages = useSelector(state => state.messages.messages)

    const isRefreshKey = useSelector(state => state.auth.isRefreshKey)

    // let dateNow = Date.now() / 1000;
    const key = localStorage.getItem("tokenKey")
    // const expKey = parseInt(localStorage.getItem("tokenKeyExpire"), 10)
    // const expKeyMinusFive = (expKey - 300)
    // console.log(expKey)
    // console.log(expKeyMinusFive)
    // console.log(dateNow)

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(getUsersTC(filterChats))
        }, 1000);
        return () => clearInterval(interval);
    }, [filterChats, messages, users])

    // console.log(messages)
    // console.log(users)
    return (
        <div className="chats-tab-open h-100">
            <div className={"main-layout h-100"}>
                <NavBar activeMenu="chat"/>
                <ChatListFunc users={users} />
                <ChatHistory chatId={path[2] != '' && path[2]}/>
                <div className="backdrop"></div>
            </div>
        </div>
    );
})
