import {API} from "../api/api";
import {getActualToken, getProfileInfoTC, setInfoUsers, setUsers} from "./users-reducer";

let initialState = {
    messages: [],
    users: {},
    messagesIsLoad: false,
    newChatId: '',
    newMessage: '',
    newToken: '',
    createNewChatId: '',
    selectNewChatType: 'support',
    currentChatId: '',
    chatID: ''
};

export const messagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_MESSAGES': {
            const stateCopy = {...state}
            // if (state.chatID === action?.messages[0]?.chatId && state.messages[0]?.message !== action.messages[0]?.message) {
            //     return {...stateCopy, messages: [...action.messages]}
            // }
            if (state.chatID !== action?.messages[0]?.chatId) {
                return stateCopy
            }
            let messages = [...stateCopy.messages]
            if (stateCopy?.messages[0]?.id === action?.messages[0]?.id) {
                return state
            }
            if (stateCopy.messages.length > 0) {
                action.messages.forEach(message => {
                    if (!messages.some(o => o.id === message.id)) {
                        messages.unshift(message)
                    }
                })
                // console.log(stateCopy.messages)
                return {...stateCopy, messages: messages, users: stateCopy.users}
                }
            return {...state, messages: action.messages, users: action.users}
        }
        case 'FETCH_MORE_MESSAGES': {
            // console.log(action.messages)
            return {...state, messages: [...state.messages.concat(action.messages) ]}
        }
        case 'LOADING_MESSAGES': {
            return {...state, messagesIsLoad: action.value}
        }
        case 'SEND_MESSAGE': {
            console.log([0])
            // debugger
            return {[0]: [action.message],...state}
        }
        case 'SELECT_NEW_CHAT': {
            return {...state, selectNewChatType: action.newChatType}
        }
        case 'CREATE_NEW_CHAT': {
            return {...state, createNewChatId: action.nChatId}
        }
        case 'CHANGE_CHAT_CLEAR_MESSAGES': {
            return {...state, messages: []}
        }
        case 'SET_CHAT_ID': {
            return {...state, chatID: action.chatId}
        }
        case 'EDIT_MESSAGE': {
            let newMes = state.messages.map((mes) => {
                if (mes.id === action.id) {
                    mes.message = action.message
                    mes.isEdit = action.isEdit
                }
                return mes
            })
            return {...state, messages: newMes}
        }
        default:
            return state;
    }
}

export const editMessageAC = (id, message, isEdit) => ({type: 'EDIT_MESSAGE', id, message, isEdit})

export const clearMessagesChangeChat = () => ({type: 'CHANGE_CHAT_CLEAR_MESSAGES'})
export const setMessages = (messages, users, chatId) => ({type: 'SET_MESSAGES', messages, users, chatId})
export const fetchMoreMessages = (messages) => ({type: 'FETCH_MORE_MESSAGES', messages})
export const sendMessages = (message) => ({type: 'SEND_MESSAGE', message})
export const messagesIsLoad = (value) => ({type: 'LOADING_MESSAGES', value})
export const selectNewChatTypeAC = (newChatType) => ({type: 'SELECT_NEW_CHAT', newChatType})
export const createNewChat = (nChatId) => ({type: 'CREATE_NEW_CHAT', nChatId})
export const addStoreChatID = (chatId) => ({type: 'SET_CHAT_ID', chatId})

export const getMessagesTC = (chatId, tokenKey, limit, offset) => (dispatch) => {
    API.getMessages(chatId, tokenKey, limit, offset)
        .then((res) => {
            // debugger
            if(res.data.result) {
                dispatch(setMessages(res.data.result.messages, res.data.result.users, chatId))
            }
        })
        dispatch(messagesIsLoad(true))
}
export const getMoreMessagesTC = (chatId, tokenKey, limit, offset) => (dispatch) => {
    API.getMessages(chatId, tokenKey, limit, offset)
        .then((res) => {
            dispatch(fetchMoreMessages(res.data.result.messages))
        })
}

export const setChatIdTC = (chatId) => (dispatch) => {
    dispatch(addStoreChatID(chatId))
}

export const sentMessageTC = (chatId, tokenKey, message) => async (dispatch) => {
    // debugger
    const tokenKey = await getActualToken()
    API.sendMessage(chatId, tokenKey, message)
        .then((res) => {
            dispatch(sendMessages(message))
        })
}

export const createNewChatTC = (tokenKey, hashUser, chatType, message, history) => (dispatch) => {
    let chatId = undefined
    // debugger
    API.createChat(tokenKey, hashUser, chatType)
        .then((res) => {
            // dispatch(createNewChat(res.data.result.chatId))
            // debugger
            chatId = res.data.result.chatId
            return API.sendMessage(res.data.result.chatId, tokenKey, message)
        })
        .then(() => {
            history('/Chat/' + chatId)
        })
}




























