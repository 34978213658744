import {API} from "../api/api";
let initialState = {
    requests: {},
    // usersDocs: []
    usersDocs: {}
}
export const businessReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_IS_BUSINESS': {
            return {...state, requests: action.requests}
        }
        case 'GET_BA_DOCS': {
            return {...state, usersDocs: {...state.usersDocs, [action.user]: action.docs}}
        }
        default:
            return state;
    }
}

export const getIsBusiness = (requests) => ({type: 'GET_IS_BUSINESS', requests})
export const getBaDocs = (docs, user) => ({type: 'GET_BA_DOCS', docs, user})

export const getIsBusinessTC = (tokenKey) => (dispatch) => {
    API.getIsBusinessRequests(tokenKey)
        .then((res) => {
            // debugger
            dispatch(getIsBusiness(res?.data?.result?.users))
        })
}

export const confirmBusinessTC = (tokenKey, hashUser) => async (dispatch) => {
    API.commitIsBusiness(tokenKey, hashUser)
        .then((res) => {
            return res
        })
        .then(() => {
            dispatch(getIsBusinessTC(tokenKey))
        })

}

export const rejectBusinessTC = (tokenKey, hashUser) => async (dispatch) => {
    API.declineIsBusiness(tokenKey, hashUser)
        .then((res) => {
            return res
        })
        .then(() => {
            dispatch(getIsBusinessTC(tokenKey))
        })

}

export const getBaDocsTC = (tokenKey, hashUser) => (dispatch) => {
    API.getUploadedDocs(tokenKey, hashUser)
        .then((res) => {
            // debugger
            dispatch(getBaDocs(res.data.result.data, hashUser))
        })
}
