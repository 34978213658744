import {applyMiddleware, combineReducers, createStore} from "redux";
import usersReducer from "./users-reducer";
import thunkMiddleware from "redux-thunk";
import {authReducer} from "./auth-reducer";
import {messagesReducer} from "./messages-reducer";
import {errorsReducer} from "./errors-reducer";
import {businessReducer} from "./business-reducer";
import {complainersReducer} from "./complainer-reducer";
import {configureStore} from "@reduxjs/toolkit";

let reducers = combineReducers({
    usersPage: usersReducer,
    auth: authReducer,
    messages: messagesReducer,
    errors: errorsReducer,
    business: businessReducer,
    complainers: complainersReducer
});

let store = createStore(reducers, applyMiddleware(thunkMiddleware));

// export const store = configureStore({
//     reducer: reducers,
//     middleware: getDefaultMiddleware => getDefaultMiddleware().prepend(thunkMiddleware)
// })

window.store = store;

export default store;
