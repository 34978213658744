import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ThemeColor} from "../../Config/Config";
import Avatar from "react-avatar";
import uuid from 'react-uuid'
import {ReactComponent as DeleteSvg} from "../../assets/media/icons/block.svg";
import {ReactComponent as ChatPlusSvg} from "../../assets/media/icons/chatplus.svg";
import {useWindowWidth} from "@react-hook/window-size";
import {Dropdown, DropdownButton} from "react-bootstrap";
import {getComplaintsListTC} from "../../Redux/complainer-reducer";

export const ComplaintsList = () => {
    const state = {
        themeColor: Boolean(localStorage.getItem("theme"))
            ? parseInt(localStorage.getItem("theme"))
            : ThemeColor.Light,
        isRemember: false,
        isRTL: Boolean(localStorage.getItem("rtl")),
    };
    useEffect(() => {
        let classList = document.body.classList;
        if (classList.length === 0 && state.themeColor === ThemeColor.Dark) {
            document.body.classList.add("dark-skin");
        }
    }, [state.themeColor])
    const dispatch = useDispatch()
    const key = localStorage.getItem("tokenKey")
    const width = useWindowWidth()

    const complainers = useSelector(state => state.complainers.complainersList)
    const complainersUsers = useSelector(state => state.complainers.complainersUsers)
    const complainersToUsers = useSelector(state => state.complainers.complainersToUsers)


    useEffect(() => {
        let dateNow = Date.now() / 1000;
        dispatch(getComplaintsListTC(key, 0,1145799999, dateNow));
    }, [key])


    return (
        <main className="main">
            <div className="chats">
                <div className="d-flex flex-column h-100 w-100">
                    <div className={"main-layout"}>
                        <aside style={{flex: 11}}>
                            <div className="calls px-0" style={{flexDirection: 'column', paddingBottom: 20}}>
                                <div className="page-main-heading sticky-top py-2 px-3">
                                    <div className="pl-2 pl-xl-0" style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '100%'}}>
                                        <h5 className="font-weight-semibold"
                                            style={{marginTop: 10, marginBottom: 10}}>List of
                                            complaints</h5>
                                        <DropdownButton
                                            className="border-0 f-16 p-0 min-w-150 cut-texts"
                                            variant="outline-default"
                                            title={'all'}

                                        >
                                            <Dropdown.Item>All Calls</Dropdown.Item>
                                            <Dropdown.Item>Incoming</Dropdown.Item>
                                            <Dropdown.Item>Outgoing</Dropdown.Item>
                                            <Dropdown.Item>Missed Calls</Dropdown.Item>
                                        </DropdownButton>
                                    </div>
                                </div>
                                <div className="" style={{position: 'fixed', width: 'calc(100% - 70px)', zIndex: 1}}>
                                    <div style={{display: 'flex', width: 100 + '%'}}>
                                        <div className="container-xl">
                                            <div className="row calls-log">
                                                <div className="col" style={{display: 'flex', marginTop: 65, color: 'white', fontWeight: 'bold', paddingTop: 20, paddingBottom: 20, background: '#323333'}}>
                                                    <div className="col-3">Complainer</div>
                                                    <div className="col-3">Recipient of the complaint</div>
                                                    <div className="col-2">Reason</div>
                                                    <div className="col-4" style={{textAlign: 'right', paddingRight: 45}}>What to do</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-xl" style={{marginTop: 60}}>
                                    <div className="row calls-log">
                                        <div className="col">
                                            {
                                                complainers && complainers.map((complaint) => {

                                                    const index = complainers.indexOf(complaint)
                                                    // console.log(index)
                                                    if (complainersUsers[index]?.nickname === undefined) {
                                                        return
                                                    } else
                                                    return (
                                                        <div className="card businessCard" key={uuid()}>
                                                            <div className="card-body">
                                                                <div className="media" style={{alignItems: 'center', justefyContent: 'space-between'}}>
                                                                    <div className="col-3">
                                                                        <div style={{display: 'flex'}}>
                                                                            <div className="avatar-primary mr-2">
                                                                                <span>
                                                                                    <Avatar
                                                                                        name={complainersUsers[index]?.nickname}
                                                                                        size={48}
                                                                                        round
                                                                                        src={complainersUsers[index]?.avatar}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                            <div className="media-body">
                                                                                <h6 style={{marginBottom: 0}}>
                                                                                    {
                                                                                        // console.log(complainersUsers[index]?.nickname)
                                                                                    }
                                                                                    {
                                                                                        complainersUsers[index]?.nickname !== undefined &&
                                                                                        complainersUsers[index]?.nickname
                                                                                    }
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <div style={{display: 'flex'}}>
                                                                            <div className="avatar-primary mr-2">
                                                                                <span>

                                                                                    <Avatar
                                                                                        name={complainersToUsers[index]?.nickname}
                                                                                        size={48}
                                                                                        round
                                                                                        src={complainersToUsers[index]?.avatar}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                            <div className="media-body">
                                                                                <h6 style={{marginBottom: 0}}>
                                                                                    {complainersToUsers[index]?.nickname}
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-2">
                                                                        <div style={{display: 'flex'}}>
                                                                            <DropdownButton
                                                                                className="border-0 f-16 p-0 min-w-300 cut-texts w-100"
                                                                                variant="outline-default"
                                                                                title={'all'}
                                                                                // size={'lg'}
                                                                            >
                                                                                <Dropdown.Item>All Calls</Dropdown.Item>
                                                                                <Dropdown.Item>Incoming</Dropdown.Item>
                                                                                <Dropdown.Item>Outgoing</Dropdown.Item>
                                                                                <Dropdown.Item>Missed Calls</Dropdown.Item>
                                                                            </DropdownButton>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                                            {
                                                                                width > 1000 ?
                                                                                    <div className="media-options">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-outline-danger mx-1">
                                                                                            Reject
                                                                                        </button>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-primary mx-1">
                                                                                            Accept request
                                                                                        </button>
                                                                                    </div> :
                                                                                    <div>
                                                                                        <button className="btn btn-link text-muted"
                                                                                                style={{padding: 2, margin: 2}}>
                                                                                            <DeleteSvg className="hw-24"
                                                                                                       style={{color: '#ff337c'}}/>
                                                                                        </button>
                                                                                        <button className="btn btn-link text-muted"
                                                                                                style={{padding: 2, margin: 2}}>
                                                                                            <ChatPlusSvg className="hw-24"
                                                                                                         style={{color: '#44a675'}}/>
                                                                                        </button>
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </main>
    );

}
export default ComplaintsList;
