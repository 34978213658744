import {API} from "../api/api";
import {loginErrorsReducerAC, error} from "./errors-reducer";

const initState = {
    isLoggedIn: false,
    waitingResponce: false,
    validInps: false,
    isRefreshKey: false
}

export const authReducer = (state = initState, action)=> {
    switch (action.type) {
        case 'login/SET-IS-LOGGED-IN': {
            return {...state, isLoggedIn: action.value}
        }
        case 'login/WAITING-RESPONCE': {
            return {...state, waitingResponce: action.value}
        }
        case 'login/VALID-INPS': {
            return {...state, validInps: action.value}
        }
        case 'IS_REFRESH_TOKEN': {
            return {...state, isRefreshKey: action.refreshToken}
        }
        default:
            return state
    }
}

export const authLoginAC = (value) => {
    return {type: 'login/SET-IS-LOGGED-IN', value}
}
export const authLoginWaitingResponceAC = (value) => {
    return {type: 'login/WAITING-RESPONCE', value}
}
export const authLoginValidInpsAC = (value) => {
    return {type: 'login/VALID-INPS', value}
}
export const refreshTokenAC = (refreshToken) => {
    return {type: 'IS_REFRESH_TOKEN', refreshToken}
}
export const loginTC = (login, password) => (dispatch) => {
    dispatch(authLoginWaitingResponceAC(true))
    API.authLogin(login, password)
        .then((res) => {
            // debugger
            if(res.data.result) {
                // let dateNow = Date.now();
                let dateNow = Date.now() / 1000;
                console.log(dateNow)
                localStorage.setItem("tokenKey", res.data.result.access_token);
                localStorage.setItem("tokenKeyRefresh", res.data.result.refresh_token);
                // localStorage.setItem("tokenKeyExpire", res.data.result.expire_access);
                localStorage.setItem("tokenKeyExpire", dateNow + res.data.result.durationAccess);
                // localStorage.setItem("tokenKeyRefreshExpire", res.data.result.expire_refresh);
                localStorage.setItem("tokenKeyRefreshExpire", dateNow + res.data.result.durationRefresh);
                // localStorage.setItem("durationTokenAccess", res.data.result.durationAccess);
                // localStorage.setItem("durationTokenRefresh", res.data.result.durationRefresh);
                // localStorage.setItem("tokenDieTime", dateNow + 35700000)
                dispatch(authLoginAC(true))
                dispatch(loginErrorsReducerAC({}))
            }
            if(res.data.error) {
                dispatch(loginErrorsReducerAC(res.data.error))
                let state = window.store.getState();
                error(state.errors.message)
                dispatch(authLoginWaitingResponceAC(false))
                dispatch(authLoginValidInpsAC(true))
                setTimeout(() => {
                    dispatch(authLoginValidInpsAC(false))
                }, 3000)
            }
        })
}
export const logOutTC = () => (dispatch) => {
    dispatch(authLoginAC(false))
    dispatch(authLoginWaitingResponceAC(false))
}

export const refreshTokenTC = (tokenKey) => (dispatch) => {
    dispatch(refreshTokenAC(true))
    // debugger
    // localStorage.removeItem("tokenKey");
    // localStorage.removeItem("tokenKeyRefresh");
    // localStorage.removeItem("tokenKeyExpire");
    // localStorage.removeItem("tokenKeyRefreshExpire");
    API.refreshTokens(tokenKey)

        .then((res) => {
            if(res.data.result) {
                debugger
                localStorage.setItem("tokenKey", res.data.result.access_token);
                localStorage.setItem("tokenKeyRefresh", res.data.result.refresh_token);
                localStorage.setItem("tokenKeyExpire", res.data.result.expire_access);
                localStorage.setItem("tokenKeyRefreshExpire", res.data.result.expire_refresh);
            }
        })

        .then(dispatch(refreshTokenAC(false)))
}





















