import React, {useEffect} from "react";
import ChatAction from "../../ChatAction/ChatAction";
import {ReactComponent as SearchSvg} from "../../../assets/media/icons/search.svg";
import Friend from "./Friend";
import {useDispatch, useSelector} from "react-redux";
import {fetchMoreUsersListTC, setUsersListTC} from "../../../Redux/users-reducer";
import {FriendRouter} from "./FriendsRouter";
import InfiniteScroll from "react-infinite-scroll-component";
import uuid from 'react-uuid'

export const FriendListFunc = () => {

    const key = localStorage.getItem("tokenKey")
    const dispatch = useDispatch();
    const users = useSelector(state => state.usersPage.usersList)
    useEffect(() => {
        dispatch(setUsersListTC('a'))
    }, [])
    const searchUsers = (e) => {
        dispatch(setUsersListTC(e.target.value))
    }
    const fetchMoreUsers = () => {
        dispatch(fetchMoreUsersListTC(key))
    }
    // debugger

    return (
        <>

        <aside className="sidebar">
            <div className="tab-content">
                <div className="tab-pane active" id="friends-content">
                    <div className="d-flex flex-column h-100">
                        <div className="hide-scrollbar" id="friendsList">
                            <div className="sidebar-header sticky-top p-2">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="font-weight-semibold mb-0">Friends</h5>
                                    <ChatAction/>
                                </div>
                                <div className="sidebar-sub-header">
                                    <form className="form-inline w-100">
                                        <div className="input-group w-100">
                                            <input
                                                type="text"
                                                className="form-control search border-right-0 transparent-bg pr-0"
                                                placeholder="Search users..."
                                                onChange={searchUsers}
                                            />
                                            <div className="input-group-append">
                                                <div
                                                    className="input-group-text transparent-bg border-left-0"
                                                    role="button"
                                                >
                                                    <SearchSvg className="text-muted hw-20"/>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <ul className="contacts-list" id="friendsTab">
                                <InfiniteScroll
                                    dataLength={users?.length + 50}
                                    next={fetchMoreUsers}
                                    hasMore
                                    loader={<h6>Loading chats list...</h6>}
                                    scrollableTarget="friendsList"
                                >
                                {/*<li><small className="font-weight-medium text-uppercase text-muted">W</small></li>*/}
                                {
                                    users && users.map((user) => {
                                        let newAvatarPath
                                        if (user?.avatar) {
                                            newAvatarPath = user.avatar.split('.')
                                        }
                                        return (
                                            <Friend
                                                key={uuid()}
                                                firstName={user.firstName}
                                                lastName={user.lastName}
                                                nickName={user.nickname}
                                                email={user.email}
                                                avatar={newAvatarPath !== undefined && 'https://cdn.fanter.com/' + newAvatarPath[0] + '-m.' + newAvatarPath[1]}
                                                locationCountry={user?.country}
                                                locationTown={user?.town}
                                                hashUser={user.hashUser}
                                            />
                                        )
                                    })
                                }
                                </InfiniteScroll>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
        </>
    );
}

