import React, { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

import { ReactComponent as SearchSvg } from "../../assets/media/icons/search.svg";
import {setFilterChats} from "../../Redux/users-reducer";
import {useDispatch, useSelector} from "react-redux";

// Top chat filter
const ChatFilter = (props) => {
  const [selectedTitle, setSelectedTitle] = useState("all");

  const dispatch = useDispatch();

  const filterChat = useSelector(state => state.usersPage.filterChats)

  const handleChange = (eventKey) => {
    setSelectedTitle(eventKey)
    dispatch(setFilterChats(eventKey))
  };

  return (
    <div className="sidebar-sub-header">
      <DropdownButton
        className="border-0 f-16 p-0 min-w-150 cut-texts"
        variant="outline-default"
        // title={selectedTitle}
        title={filterChat}
        onSelect={handleChange}
      >
        <Dropdown.Item eventKey={'all'}>
          All
        </Dropdown.Item>
        <Dropdown.Item eventKey={'support'}>
          Support
        </Dropdown.Item>
        <Dropdown.Item eventKey={'creator'} >
          Creators
        </Dropdown.Item>
      </DropdownButton>

      <form className="form-inline pl-2" style={{width: 100 + '%'}}>
        <div className="input-group" style={{width: 100 + '%'}}>
          <input
            type="text"
            className="form-control search border-right-0 pr-0"
            placeholder="Search users..."
            value={props.search}
            onChange={props.handleSearch}
          />
          <div className="input-group-append">
            <div
              className="input-group-text border-left-0"
              role="button"
            >
              <SearchSvg className="text-muted hw-20" />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default ChatFilter;
