import React, {useEffect} from "react";
import ChatMessage from "./Message/ChatMessage";
import moment from "moment";
import 'moment/locale/ru';
import ChatAction from "../../ChatAction/ChatAction";
import ChatFilter from "../../FilterDropdowns/ChatFilter";
import {useDispatch, useSelector} from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {fetchUsersTC, getUsersTC, setInfoUsers, setIsOnlineUsers, setUsers} from "../../../Redux/users-reducer";
import uuid from "react-uuid";
import {API} from "../../../api/api";

function isToday(momentDate) {
    return momentDate.isSame(moment(), 'd');
}

export const ChatListFunc = React.memo((props) => {

    const dispatch = useDispatch()
    const infoUsers = useSelector(state => state.usersPage.infoUsers)
    const isOnline = useSelector(state => state.usersPage.isOnline)
    const users = useSelector(state => state.usersPage.users)
    const key = localStorage.getItem("tokenKey")
    const filterChats = useSelector(state => state.usersPage.filterChats)

    const fetchMoreUsers = () => {
        dispatch(fetchUsersTC(key, filterChats, users.length + 50))
    }

    // useEffect(() => {
    //     Object.keys(infoUsers).forEach((user) => {
    //         dispatch(setIsOnlineUsers(user))
    //     })
    // }, [infoUsers])

    // console.log(infoUsers)
// debugger
    return (
        <aside className="sidebar">
            <div className="tab-content" id="tab-contentInf">
                <div className="tab-pane active" id="chats-content">
                    <div className="d-flex flex-column h-100">
                        <div className="hide-scrollbar h-100" id="chatContactsList">
                            <div className="sidebar-header sticky-top p-2">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="font-weight-semibold mb-0 p-2">Chats</h5>
                                    <ChatAction />
                                </div>
                                <ChatFilter/>
                            </div>
                            <ul
                                className="contacts-list"
                                id="chatContactTab"
                                data-chat-list=""
                            >
                                <InfiniteScroll
                                    dataLength={users.length + 50}
                                    next={fetchMoreUsers}
                                    hasMore
                                    loader={<h6>Loading chats list...</h6>}
                                    scrollableTarget="chatContactsList"
                                >
                                {
                                    props.users.map((user) => {
                                        // console.log(props.users)
                                        // console.log(infoUsers?.[user.creatorId]?.isBusiness)
                                        return <ChatMessage
                                            chatId={user.chatId}
                                            key={uuid()}
                                            uname={user.name}
                                            nick={infoUsers[user.creatorId]?.nickname}
                                            lastMessage={user.lastMessageText}
                                            lastMessageTime={
                                                isToday(moment.unix(user.lastMessageTStamp)) ?
                                                    moment.unix(user.lastMessageTStamp).format("HH:mm") :
                                                    moment.unix(user.lastMessageTStamp).locale("ru").format("L")
                                            }
                                            creatorID={user.creatorId}
                                            infoUsers={infoUsers[user.creatorId]}
                                            chatType={user.chatName}
                                            isOnline={isOnline?.[user.creatorId]}
                                            isBusiness={infoUsers?.[user.creatorId]?.isBusiness}
                                        />
                                    })
                                }
                                </InfiniteScroll>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    );
})


